:root {
  .mission-calendar {
    .cal-month-view .cal-day-cell,
    .cal-month-view .cal-cell-row:hover,
    .cal-month-view .cal-cell-row .cal-cell:hover,
    .cal-month-view .cal-cell.cal-has-events.cal-open,
    .cal-month-view {
      background-color: transparent;
    }

    .cal-month-view .cal-day-cell {
      min-height: 0;
    }

    .cal-month-view .cal-days,
    .cal-month-view .cal-days .cal-cell-row,
    .cal-month-view .cal-day-cell:not(:last-child) {
      border: none;
    }
  }

  .mission-day-view {
    width: 100%;

    .cal-week-view .cal-hour:not(:last-child) .cal-hour-segment,
    .cal-week-view .cal-hour:last-child :not(:last-child) .cal-hour-segment {
      border: none;
    }

    .cal-hour,
    .cal-week-view
      .cal-time-events
      .cal-day-columns:not(.cal-resize-active)
      .cal-hour-segment:hover {
      background-color: var(--way-color-l1);
    }

    .cal-hour {
      &:not(:last-child) {
        border-bottom: 1px solid var(--way-color-m1);
      }
    }

    .cal-event,
    .cal-week-view {
      border: none;
    }

    .cal-event {
      color: #fff;
      border-radius: 0.66rem;
      padding: 0.5rem 1rem;
      min-height: 52px;
      min-width: 65px;
      box-shadow: 0px 2px 5px -1px rgb(0 0 0 / 5%);

      &.pastNotClosed {
        background-color: var(--way-color-past-not-closed);
      }

      &.transfert {
        background-color: var(--way-color-transfert);
      }

      &.disposal {
        color: var(--way-color-n2);
        background-color: var(--way-color-disposal);
      }

      &.airportPickUp {
        background-color: var(--way-color-airport-pickup);
      }

      &.airportDropOff {
        background-color: var(--way-color-airport-dropoff);
      }
    }

    .cal-day-view .cal-events-container {
      margin: 0 0.25rem;
    }

    .cal-time {
      position: absolute;
      left: -20%;
      top: -25%;
    }

    .cal-week-view .cal-time-events {
      border: 1px solid var(--way-color-m1);
      width: calc(100% - 40px);
      margin-left: auto;
    }

    .custom-hour.cal-cell-row.cal-header {
      overflow: visible;
    }

    .cal-week-view .cal-time-events .cal-event {
      line-height: normal;
    }
  }
}
