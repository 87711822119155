:root {
  --base-content-padding: 0.7em;
  --mission-block-padding-top: 0.3em;
  --mission-block-text-margin-left: 1.6em;
}

.is-flex {
  display: flex;
}

.bg-white {
  --background: white;
}

/* work-sans-100 - vietnamese_latin-ext_latin */
@font-face {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 100;
  src: url("../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-100.eot"); /* IE9 Compat Modes */
  src: local(""), url("../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-100.eot?#iefix") format("embedded-opentype"), url("../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-100.woff2") format("woff2"), url("../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-100.woff") format("woff"), url("../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-100.ttf") format("truetype"), url("../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-100.svg#WorkSans") format("svg"); /* Legacy iOS */
}
/* work-sans-200 - vietnamese_latin-ext_latin */
@font-face {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 200;
  src: url("../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-200.eot"); /* IE9 Compat Modes */
  src: local(""), url("../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-200.eot?#iefix") format("embedded-opentype"), url("../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-200.woff2") format("woff2"), url("../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-200.woff") format("woff"), url("../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-200.ttf") format("truetype"), url("../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-200.svg#WorkSans") format("svg"); /* Legacy iOS */
}
/* work-sans-300 - vietnamese_latin-ext_latin */
@font-face {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 300;
  src: url("../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-300.eot"); /* IE9 Compat Modes */
  src: local(""), url("../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-300.eot?#iefix") format("embedded-opentype"), url("../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-300.woff2") format("woff2"), url("../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-300.woff") format("woff"), url("../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-300.ttf") format("truetype"), url("../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-300.svg#WorkSans") format("svg"); /* Legacy iOS */
}
/* work-sans-regular - vietnamese_latin-ext_latin */
@font-face {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 400;
  src: url("../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-regular.eot"); /* IE9 Compat Modes */
  src: local(""), url("../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-regular.eot?#iefix") format("embedded-opentype"), url("../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-regular.woff2") format("woff2"), url("../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-regular.woff") format("woff"), url("../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-regular.ttf") format("truetype"), url("../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-regular.svg#WorkSans") format("svg"); /* Legacy iOS */
}
/* work-sans-500 - vietnamese_latin-ext_latin */
@font-face {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 500;
  src: url("../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-500.eot"); /* IE9 Compat Modes */
  src: local(""), url("../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-500.eot?#iefix") format("embedded-opentype"), url("../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-500.woff2") format("woff2"), url("../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-500.woff") format("woff"), url("../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-500.ttf") format("truetype"), url("../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-500.svg#WorkSans") format("svg"); /* Legacy iOS */
}
/* work-sans-600 - vietnamese_latin-ext_latin */
@font-face {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 600;
  src: url("../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-600.eot"); /* IE9 Compat Modes */
  src: local(""), url("../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-600.eot?#iefix") format("embedded-opentype"), url("../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-600.woff2") format("woff2"), url("../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-600.woff") format("woff"), url("../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-600.ttf") format("truetype"), url("../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-600.svg#WorkSans") format("svg"); /* Legacy iOS */
}
/* work-sans-700 - vietnamese_latin-ext_latin */
@font-face {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 700;
  src: url("../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-700.eot"); /* IE9 Compat Modes */
  src: local(""), url("../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-700.eot?#iefix") format("embedded-opentype"), url("../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-700.woff2") format("woff2"), url("../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-700.woff") format("woff"), url("../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-700.ttf") format("truetype"), url("../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-700.svg#WorkSans") format("svg"); /* Legacy iOS */
}
/* work-sans-800 - vietnamese_latin-ext_latin */
@font-face {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 800;
  src: url("../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-800.eot"); /* IE9 Compat Modes */
  src: local(""), url("../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-800.eot?#iefix") format("embedded-opentype"), url("../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-800.woff2") format("woff2"), url("../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-800.woff") format("woff"), url("../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-800.ttf") format("truetype"), url("../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-800.svg#WorkSans") format("svg"); /* Legacy iOS */
}
/* work-sans-900 - vietnamese_latin-ext_latin */
@font-face {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 900;
  src: url("../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-900.eot"); /* IE9 Compat Modes */
  src: local(""), url("../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-900.eot?#iefix") format("embedded-opentype"), url("../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-900.woff2") format("woff2"), url("../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-900.woff") format("woff"), url("../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-900.ttf") format("truetype"), url("../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-900.svg#WorkSans") format("svg"); /* Legacy iOS */
}
/* work-sans-200italic - vietnamese_latin-ext_latin */
@font-face {
  font-family: "Work Sans";
  font-style: italic;
  font-weight: 200;
  src: url("../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-200italic.eot"); /* IE9 Compat Modes */
  src: local(""), url("../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-200italic.eot?#iefix") format("embedded-opentype"), url("../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-200italic.woff2") format("woff2"), url("../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-200italic.woff") format("woff"), url("../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-200italic.ttf") format("truetype"), url("../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-200italic.svg#WorkSans") format("svg"); /* Legacy iOS */
}
/* work-sans-100italic - vietnamese_latin-ext_latin */
@font-face {
  font-family: "Work Sans";
  font-style: italic;
  font-weight: 100;
  src: url("../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-100italic.eot"); /* IE9 Compat Modes */
  src: local(""), url("../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-100italic.eot?#iefix") format("embedded-opentype"), url("../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-100italic.woff2") format("woff2"), url("../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-100italic.woff") format("woff"), url("../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-100italic.ttf") format("truetype"), url("../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-100italic.svg#WorkSans") format("svg"); /* Legacy iOS */
}
/* work-sans-300italic - vietnamese_latin-ext_latin */
@font-face {
  font-family: "Work Sans";
  font-style: italic;
  font-weight: 300;
  src: url("../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-300italic.eot"); /* IE9 Compat Modes */
  src: local(""), url("../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-300italic.eot?#iefix") format("embedded-opentype"), url("../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-300italic.woff2") format("woff2"), url("../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-300italic.woff") format("woff"), url("../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-300italic.ttf") format("truetype"), url("../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-300italic.svg#WorkSans") format("svg"); /* Legacy iOS */
}
/* work-sans-500italic - vietnamese_latin-ext_latin */
@font-face {
  font-family: "Work Sans";
  font-style: italic;
  font-weight: 500;
  src: url("../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-500italic.eot"); /* IE9 Compat Modes */
  src: local(""), url("../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-500italic.eot?#iefix") format("embedded-opentype"), url("../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-500italic.woff2") format("woff2"), url("../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-500italic.woff") format("woff"), url("../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-500italic.ttf") format("truetype"), url("../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-500italic.svg#WorkSans") format("svg"); /* Legacy iOS */
}
/* work-sans-italic - vietnamese_latin-ext_latin */
@font-face {
  font-family: "Work Sans";
  font-style: italic;
  font-weight: 400;
  src: url("../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-italic.eot"); /* IE9 Compat Modes */
  src: local(""), url("../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-italic.eot?#iefix") format("embedded-opentype"), url("../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-italic.woff2") format("woff2"), url("../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-italic.woff") format("woff"), url("../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-italic.ttf") format("truetype"), url("../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-italic.svg#WorkSans") format("svg"); /* Legacy iOS */
}
/* work-sans-700italic - vietnamese_latin-ext_latin */
@font-face {
  font-family: "Work Sans";
  font-style: italic;
  font-weight: 700;
  src: url("../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-700italic.eot"); /* IE9 Compat Modes */
  src: local(""), url("../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-700italic.eot?#iefix") format("embedded-opentype"), url("../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-700italic.woff2") format("woff2"), url("../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-700italic.woff") format("woff"), url("../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-700italic.ttf") format("truetype"), url("../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-700italic.svg#WorkSans") format("svg"); /* Legacy iOS */
}
/* work-sans-600italic - vietnamese_latin-ext_latin */
@font-face {
  font-family: "Work Sans";
  font-style: italic;
  font-weight: 600;
  src: url("../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-600italic.eot"); /* IE9 Compat Modes */
  src: local(""), url("../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-600italic.eot?#iefix") format("embedded-opentype"), url("../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-600italic.woff2") format("woff2"), url("../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-600italic.woff") format("woff"), url("../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-600italic.ttf") format("truetype"), url("../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-600italic.svg#WorkSans") format("svg"); /* Legacy iOS */
}
/* work-sans-800italic - vietnamese_latin-ext_latin */
@font-face {
  font-family: "Work Sans";
  font-style: italic;
  font-weight: 800;
  src: url("../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-800italic.eot"); /* IE9 Compat Modes */
  src: local(""), url("../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-800italic.eot?#iefix") format("embedded-opentype"), url("../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-800italic.woff2") format("woff2"), url("../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-800italic.woff") format("woff"), url("../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-800italic.ttf") format("truetype"), url("../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-800italic.svg#WorkSans") format("svg"); /* Legacy iOS */
}
/* work-sans-900italic - vietnamese_latin-ext_latin */
@font-face {
  font-family: "Work Sans";
  font-style: italic;
  font-weight: 900;
  src: url("../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-900italic.eot"); /* IE9 Compat Modes */
  src: local(""), url("../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-900italic.eot?#iefix") format("embedded-opentype"), url("../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-900italic.woff2") format("woff2"), url("../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-900italic.woff") format("woff"), url("../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-900italic.ttf") format("truetype"), url("../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-900italic.svg#WorkSans") format("svg"); /* Legacy iOS */
}
:root {
  --ion-font-family: "Work Sans";
  font-family: "Work Sans";
  font-size: 12px;
}
:root .text-md {
  font-size: 1.16rem;
}
:root .text-lg {
  font-size: 1.33rem;
}
:root .text-xl {
  font-size: 1.5rem;
}
:root .text-2xl {
  font-size: 1.83rem;
}
:root .text-3xl {
  font-size: 2.33rem;
}
:root .font-bold {
  font-weight: bold;
}
:root .font-medium {
  font-weight: 500;
}
:root .font-semibold {
  font-weight: 600;
}
:root .uppercase {
  text-transform: uppercase;
}
:root .capitalize {
  text-transform: capitalize;
}
:root .text-center {
  text-align: center;
}
:root .title.dark,
:root .head-title.dark,
:root .subtitle.dark {
  color: var(--way-color-d2);
}
:root .title.brand,
:root .head-title.brand,
:root .subtitle.brand {
  color: var(--way-color-brand);
}
:root .title,
:root .head-title {
  color: #fff;
  font-weight: 700;
}
:root .head-title {
  font-size: 1.83rem;
  text-align: center;
}
:root .head-title.start {
  text-align: start;
}
:root .title {
  font-size: 1.5rem;
  font-weight: 700;
}
:root .subtitle {
  font-size: 1.16rem;
  font-weight: 500;
  color: var(--ion-color-waynium-light1);
}
:root .subtitle.lg {
  font-size: 1.33rem;
}
:root .subtitle.d1 {
  color: var(--way-color-d1);
}
:root ion-accordion ion-label {
  font-size: 1.33rem;
  font-weight: 700;
}
:root ion-accordion ion-label .optional-label {
  color: var(--way-optional-label);
  font-size: 1.16rem;
  font-weight: 500;
}
:root ion-input {
  color: var(--ion-text-color);
  font-size: 1.16rem;
  font-weight: 500;
}
:root way-partner-signin ion-input {
  color: var(--way-color-d2-immutable);
  font-size: 1.16rem;
  font-weight: 500;
}
:root .form-error {
  color: var(--way-color-danger);
  margin: 0;
  font-weight: 500;
}
:root .title-secondary {
  color: var(--way-color-title-secondary);
  font-size: 1.16rem;
  font-weight: 600;
  text-transform: uppercase;
  margin: 0 0 1rem;
}
:root .title-secondary.no-margin {
  margin: 0;
}
:root .no-margin {
  margin: 0;
}
:root .line-through {
  text-decoration: line-through;
}

.card-content-ios p {
  font-size: 1rem;
}

.text-d1 {
  color: var(--way-color-d1);
}

.text-n2 {
  color: var(--way-color-n2);
}

.text-d2-alt {
  color: var(--way-color-d2-alt);
}

.text-danger {
  color: var(--way-color-danger);
}

.text-success {
  color: var(--way-color-success);
}

/* josefin-sans-100 */
@font-face {
  font-family: "Josefin Sans";
  font-style: normal;
  font-weight: 100;
  src: local(""), url("../../assets/fonts/josefin-sans/JosefinSans-VariableFont_wght.ttf") format("truetype");
}
/* josefin-sans-200 */
@font-face {
  font-family: "Josefin Sans";
  font-style: normal;
  font-weight: 200;
  src: local(""), url("../../assets/fonts/josefin-sans/JosefinSans-VariableFont_wght.ttf") format("truetype");
}
/* josefin-sans-300 */
@font-face {
  font-family: "Josefin Sans";
  font-style: normal;
  font-weight: 300;
  src: local(""), url("../../assets/fonts/josefin-sans/JosefinSans-VariableFont_wght.ttf") format("truetype");
}
/* josefin-sans-400 */
@font-face {
  font-family: "Josefin Sans";
  font-style: normal;
  font-weight: 400;
  src: local(""), url("../../assets/fonts/josefin-sans/JosefinSans-VariableFont_wght.ttf") format("truetype");
}
/* josefin-sans-500 */
@font-face {
  font-family: "Josefin Sans";
  font-style: normal;
  font-weight: 500;
  src: local(""), url("../../assets/fonts/josefin-sans/JosefinSans-VariableFont_wght.ttf") format("truetype");
}
/* josefin-sans-600 */
@font-face {
  font-family: "Josefin Sans";
  font-style: normal;
  font-weight: 600;
  src: local(""), url("../../assets/fonts/josefin-sans/JosefinSans-VariableFont_wght.ttf") format("truetype");
}
/* josefin-sans-700 */
@font-face {
  font-family: "Josefin Sans";
  font-style: normal;
  font-weight: 700;
  src: local(""), url("../../assets/fonts/josefin-sans/JosefinSans-VariableFont_wght.ttf") format("truetype");
}
/* josefin-sans-800 */
@font-face {
  font-family: "Josefin Sans";
  font-style: normal;
  font-weight: 800;
  src: local(""), url("../../assets/fonts/josefin-sans/JosefinSans-VariableFont_wght.ttf") format("truetype");
}
/* josefin-sans-900 */
@font-face {
  font-family: "Josefin Sans";
  font-style: normal;
  font-weight: 900;
  src: local(""), url("../../assets/fonts/josefin-sans/JosefinSans-VariableFont_wght.ttf") format("truetype");
}
/* josefin-sans-100italic */
@font-face {
  font-family: "Josefin Sans";
  font-style: italic;
  font-weight: 100;
  src: local(""), url("../../assets/fonts/josefin-sans/JosefinSans-Italic-VariableFont_wght.ttf") format("truetype");
}
/* josefin-sans-200italic */
@font-face {
  font-family: "Josefin Sans";
  font-style: italic;
  font-weight: 200;
  src: local(""), url("../../assets/fonts/josefin-sans/JosefinSans-Italic-VariableFont_wght.ttf") format("truetype");
}
/* josefin-sans-300italic */
@font-face {
  font-family: "Josefin Sans";
  font-style: italic;
  font-weight: 300;
  src: local(""), url("../../assets/fonts/josefin-sans/JosefinSans-Italic-VariableFont_wght.ttf") format("truetype");
}
/* josefin-sans-400italic */
@font-face {
  font-family: "Josefin Sans";
  font-style: italic;
  font-weight: 400;
  src: local(""), url("../../assets/fonts/josefin-sans/JosefinSans-Italic-VariableFont_wght.ttf") format("truetype");
}
/* josefin-sans-500italic */
@font-face {
  font-family: "Josefin Sans";
  font-style: italic;
  font-weight: 500;
  src: local(""), url("../../assets/fonts/josefin-sans/JosefinSans-Italic-VariableFont_wght.ttf") format("truetype");
}
/* josefin-sans-600italic */
@font-face {
  font-family: "Josefin Sans";
  font-style: italic;
  font-weight: 600;
  src: local(""), url("../../assets/fonts/josefin-sans/JosefinSans-Italic-VariableFont_wght.ttf") format("truetype");
}
/* josefin-sans-700italic */
@font-face {
  font-family: "Josefin Sans";
  font-style: italic;
  font-weight: 700;
  src: local(""), url("../../assets/fonts/josefin-sans/JosefinSans-Italic-VariableFont_wght.ttf") format("truetype");
}
/* josefin-sans-800italic */
@font-face {
  font-family: "Josefin Sans";
  font-style: italic;
  font-weight: 800;
  src: local(""), url("../../assets/fonts/josefin-sans/JosefinSans-Italic-VariableFont_wght.ttf") format("truetype");
}
/* josefin-sans-900italic */
@font-face {
  font-family: "Josefin Sans";
  font-style: italic;
  font-weight: 900;
  src: local(""), url("../../assets/fonts/josefin-sans/JosefinSans-Italic-VariableFont_wght.ttf") format("truetype");
}
/** Ionic CSS Variables **/
:root {
  --ion-background-color: var(--way-style-blockbg);
  --ion-background-color-rgb: 255, 255, 255;
  --secondary-background-color: var(--way-style-pagebg);
  --ion-text-color: var(--way-color-d2);
  --ion-text-color-rgb: 47, 56, 69;
  --ion-border-color: transparent;
  --ion-toolbar-background: var(--way-style-blockbg);
  --ion-item-background: transparent;
  /** primary **/
  --ion-color-primary: var(--way-style-brand);
  --ion-color-primary-rgb: 42, 90, 164;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #103f88;
  --ion-color-primary-tint: #2a5aa4;
  /** secondary **/
  --ion-color-secondary: var(--way-color-brand);
  --ion-color-secondary-rgb: 61, 194, 255;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #36abe0;
  --ion-color-secondary-tint: #50c8ff;
  /** tertiary **/
  --ion-color-tertiary: #5260ff;
  --ion-color-tertiary-rgb: 82, 96, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #4854e0;
  --ion-color-tertiary-tint: #6370ff;
  /** success **/
  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;
  /** warning **/
  --ion-color-warning: #ff9900;
  --ion-color-warning-rgb: 255, 153, 0;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e08700;
  --ion-color-warning-tint: #ffa31a;
  /** danger **/
  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;
  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;
  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;
  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;
  /** waynium danger **/
  --ion-color-waynium-danger: #fa2f02;
  --ion-color-waynium-danger-rgb: 250, 47, 2;
  --ion-color-waynium-danger-contrast: #ffffff;
  --ion-color-waynium-danger-contrast-rgb: 255, 255, 255;
  --ion-color-waynium-danger-shade: #dc2902;
  --ion-color-waynium-danger-tint: #fb441b;
  /** waynium warning **/
  --ion-color-waynium-warning: #ff9900;
  --ion-color-waynium-warning-rgb: 255, 153, 0;
  --ion-color-waynium-warning-contrast: #000000;
  --ion-color-waynium-warning-contrast-rgb: 0, 0, 0;
  --ion-color-waynium-warning-shade: #e08700;
  --ion-color-waynium-warning-tint: #ffa31a;
  /** waynium ok **/
  --ion-color-waynium-ok: #54a900;
  --ion-color-waynium-ok-rgb: 84, 169, 0;
  --ion-color-waynium-ok-contrast: #ffffff;
  --ion-color-waynium-ok-contrast-rgb: 255, 255, 255;
  --ion-color-waynium-ok-shade: #4a9500;
  --ion-color-waynium-ok-tint: #65b21a;
  /** waynium mid #1 **/
  --ion-color-waynium-light1: #d0d4e5;
  /** WAYNIUM PALETTE **/
  /** waynium brand */
  --way-color-brand: #491057;
  /** waynium light #1 */
  --way-color-l1: #ffffff;
  /** waynium light #2 */
  --way-color-l2: #f6f8ff;
  /** waynium mid #1 */
  --way-color-m1: #e8ecfa;
  /** waynium mid #2 */
  --way-color-m2: #d0d4e5;
  /** waynium mid #2 */
  --way-color-m3: #a3acce;
  /** waynium dark #1 */
  --way-color-d1: #6c769a;
  /** waynium dark #2 */
  --way-color-d2: #2f3845;
  --way-color-n2: #15191f;
  /********** COLOR STYLES **********/
  /* Page background **/
  --way-style-pagebg: var(--way-color-l2);
  /* Block background **/
  --way-style-blockbg: var(--way-color-l1);
  /** Brand **/
  --way-style-brand: var(--way-color-brand);
  --way-style-brand-contrast: var(--way-color-l1);
  --way-style-brand-block-d1: var(--way-color-brand);
  --way-style-brand-block-d2: #e5e9f6;
  --way-style-brand-block-d3: #fff;
  --way-style-brand-block-d4: #491057;
  --way-style-block: var(--way-color-l1);
  --way-style-brand-button: var(--way-color-brand);
  --way-style-brand-button2: var(--way-color-m1-immutable);
  --ion-color-gray_waynium2: var(--ion-color-gray_waynium);
  --color-stepper-container-p: #221a38;
  --ion-toggle-bg: #d0d4e5;
  --way-color-p-signin: #d0d4e5;
  --way-style-brand-d1: #2f1436;
  --way-style-brand-d2: #1a0e1d;
  --way-color-brand-immutable: #491057;
  --way-color-brand-alt: var(--way-color-brand);
  --way-color-brand-l1: #f7eff9;
  --way-color-brand-l2: #e5ceec;
  /* Primary text **/
  --way-style-txt-primary: var(--way-color-d2);
  /* Secondary text **/
  --way-style-txt-secondary: var(--way-color-d1);
  /* Tertiary text **/
  --way-style-txt-tertiary: var(--way-color-d2);
  /* Borders & separators **/
  --way-style-block-border: var(--way-color-m1);
  --way-style-page-border: var(--way-color-m2);
  /* Box shadow **/
  --way-style-box-shadow: 0px 2px 7px -3px #48484837;
  /* danger status **/
  --way-style-danger-bg: #fcf1ee;
  /* warning status **/
  --way-style-warning-bg: #fff8e5;
  /* success status **/
  --way-style-success-bg: #e9f6db;
  /* Immutable */
  --way-color-m1-immutable: #e8ecfa;
  --way-color-d1-immutable: #6c769a;
  --way-color-d2-immutable: #2f3845;
  --way-color-danger: #c73131;
  --way-color-danger-bg: #ffecea;
  --way-color-success: #1b8b7e;
  --way-color-success-bg: #eaf5f2;
  --way-style-radio-container-disabled-bg: var(--way-color-l2);
  --way-style-radio-container-disabled-color: var(--way-color-d1);
  --button-color-1: #ffffff;
  --button-color-2: var(--way-color-brand-immutable);
  --button-color-3: var(--way-color-brand-immutable);
  --way-optional-label: #6c769a;
  --way-input-label-color: #ffffff;
  --way-symbol-color: #2f3845;
  --ion-chip-select: #ffffff;
  --ion-accordion-color: var(--way-color-m1-immutable);
  --way-color-mission-assignment: #e8ecfa;
  --way-color-m4: #475166;
  --way-color-scrollbar: #c2c9d2;
  --way-color-mission-bg: #2f3845;
  --way-color-circle-purple: #935ba2;
  --way-color-circle-bg: #491057;
  --way-color-circle: #e5ceec;
  --way-header-signup: #f6f8ff;
  --way-color-brand-alt-l1: #7b438a;
  --way-color-warning-alt: #b28400;
  --way-color-warning-alt-l1: #C69F40;
  --way-color-warning-alt-bg: #f8f1e3;
  --way-color-pending: #2a65e5;
  --way-color-pending-bg: #e9f2ff;
  --way-color-refused: #640;
  --way-color-refused-bg: #FFE5CC;
  --way-color-title-secondary: var(--way-color-d1);
  --way-color-content-wrapper: var(--way-color-d2);
  --way-color-separator-mission: var(--way-color-m2);
  --way-color-content-wrapper-mis: #FFFFFF;
  --way-bgcolor-badge-counter: #d0d4e3;
  --way-color-badge-counter: var(--way-color-d2);
  --way-color-mission-date-badge: var(--way-color-brand);
  --way-bgcolor-mission-date-badge: var(--way-color-l1);
  --way-color-title-mission-date: var(--way-color-n2);
  --way-color-brand-alt-immutable: #cf79f7;
  --way-color-past-not-closed:#ff6b63;
  --way-color-transfert: #4dafff;
  --way-color-disposal: #ffc941;
  --way-color-airport-pickup: #3db932;
  --way-color-airport-dropoff: #ff80f2;
  --way-color-d2-alt: #475266;
  --way-color-second-block: var(--way-color-l2);
  --way-step-mission-trajet: #475266;
  --way-second-text-mission-detail: var(--way-color-d1-immutable);
  --way-color-third-block: var(--way-color-l2);
}

body.dark {
  --ion-background-color: #232933;
  --ion-background-color-rgb: 0, 0, 0;
  --secondary-background-color: var(--way-style-pagebg);
  --ion-text-color: var(--way-style-txt-primary);
  --ion-text-color-rgb: 255, 255, 255;
  --ion-border-color: transparent;
  --ion-toolbar-background: var(--way-style-blockbg);
  --ion-item-background: transparent;
  --ion-color-primary: var(--way-style-brand);
  --ion-color-primary-rgb: 42, 90, 164;
  --ion-color-primary-contrast: #232933;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #103f88;
  --ion-color-primary-tint: #2a5aa4;
  --ion-color-secondary: var(--way-style-brand);
  --ion-color-secondary-rgb: 80, 200, 255;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #46b0e0;
  --ion-color-secondary-tint: #62ceff;
  --ion-color-tertiary: #6a64ff;
  --ion-color-tertiary-rgb: 106, 100, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #5d58e0;
  --ion-color-tertiary-tint: #7974ff;
  --ion-color-success: #2fdf75;
  --ion-color-success-rgb: 47, 223, 117;
  --ion-color-success-contrast: #000000;
  --ion-color-success-contrast-rgb: 0, 0, 0;
  --ion-color-success-shade: #29c467;
  --ion-color-success-tint: #44e283;
  --ion-color-warning: #ff9900;
  --ion-color-warning-rgb: 255, 213, 52;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0bb2e;
  --ion-color-warning-tint: #ffd948;
  --ion-color-danger: #ff4961;
  --ion-color-danger-rgb: 255, 73, 97;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #e04055;
  --ion-color-danger-tint: #ff5b71;
  --ion-color-dark: #f4f5f8;
  --ion-color-dark-rgb: 244, 245, 248;
  --ion-color-dark-contrast: #000000;
  --ion-color-dark-contrast-rgb: 0, 0, 0;
  --ion-color-dark-shade: #d7d8da;
  --ion-color-dark-tint: #f5f6f9;
  --ion-color-medium: #989aa2;
  --ion-color-medium-rgb: 152, 154, 162;
  --ion-color-medium-contrast: #000000;
  --ion-color-medium-contrast-rgb: 0, 0, 0;
  --ion-color-medium-shade: #86888f;
  --ion-color-medium-tint: #a2a4ab;
  --ion-color-light: #222428;
  --ion-color-light-rgb: 34, 36, 40;
  --ion-color-light-contrast: #ffffff;
  --ion-color-light-contrast-rgb: 255, 255, 255;
  --ion-color-light-shade: #1e2023;
  --ion-color-light-tint: #383a3e;
  --ion-color-step-50: #1e1e1e;
  --ion-color-step-100: #2a2a2a;
  --ion-color-step-150: #363636;
  --ion-color-step-200: #414141;
  --ion-color-step-250: #4d4d4d;
  --ion-color-step-300: #595959;
  --ion-color-step-350: #656565;
  --ion-color-step-400: #717171;
  --ion-color-step-450: #7d7d7d;
  --ion-color-step-500: #898989;
  --ion-color-step-550: #949494;
  --ion-color-step-600: #a0a0a0;
  --ion-color-step-650: #acacac;
  --ion-color-step-700: #b8b8b8;
  --ion-color-step-750: #c4c4c4;
  --ion-color-step-800: #d0d0d0;
  --ion-color-step-850: #dbdbdb;
  --ion-color-step-900: #e7e7e7;
  --ion-color-step-950: #f3f3f3;
  /** waynium mid #1 **/
  --ion-color-waynium-light1: #d0d4e5;
  /** WAYNIUM PALETTE **/
  /** waynium brand */
  --way-color-brand: #cf79f7;
  --way-style-brand-button: var(--way-color-brand);
  --way-style-brand-button2: var(--ion-color-gray_waynium);
  --ion-color-gray_waynium2: var(--way-color-d2);
  --color-stepper-container-p: var(--ion-text-color);
  --way-optional-label: #a3acce;
  --way-input-label-color: var(--ion-background-color);
  --way-symbol-color: #ffffff;
  --ion-chip-select: #15191f;
  --ion-toggle-bg: #6c769a;
  --ion-accordion-color: #2f3845;
  /** waynium light #1 */
  --way-color-l1: #242731;
  /** waynium light #2 */
  --way-color-l2: #1c1d21;
  /** waynium mid #1 */
  --way-color-m1: #3b4051;
  /** waynium mid #2 */
  --way-color-m2: #555d76;
  /** waynium dark #1 */
  --way-color-d1: #ced2dd;
  /** waynium dark #2 */
  --way-color-d2: #ffffff;
  /********** COLOR STYLES **********/
  /* Page background **/
  --way-style-pagebg: var(--way-color-l2);
  /* Block background **/
  --way-style-blockbg: var(--way-color-l1);
  /** Brand **/
  --way-style-brand: var(--way-color-brand);
  --way-style-brand-contrast: var(--way-color-l1);
  --way-style-brand-block-d1: #491057;
  --way-style-brand-block-d2: #6d748c;
  --way-style-brand-block-d3: #ced2dd;
  --way-style-brand-block-d4: #1c1d21;
  --way-style-block: #1c1d21;
  --way-style-brand-button: #cf79f7;
  --way-style-brand-d1: var(--way-color-m2);
  --way-style-brand-d2: var(--way-color-m1);
  --way-color-brand-l1: #2f3845;
  /* Primary text **/
  --way-style-txt-primary: var(--way-color-d2);
  /* Secondary text **/
  --way-style-txt-secondary: var(--way-color-d1);
  /* Tertiary text **/
  --way-style-txt-tertiary: var(--way-color-d2);
  /* Borders & separators **/
  --way-style-block-border: var(--way-color-m1);
  --way-style-page-border: var(--way-color-m2);
  /* Box shadow **/
  --way-style-box-shadow: 0px 6px 7px -3px #0000002a;
  --way-style-radio-container-disabled-bg: #6c769a29;
  --way-style-radio-container-disabled-color: #6c769a;
  --button-color-1: #2f3845;
  --button-color-2: #ffffff;
  --button-color-3: var(--way-color-brand);
  --way-color-circle: #475266;
  --way-color-circle-bg: #fff;
  --way-color-circle-purple: #935ba2;
  --way-header-signup: #232933;
  --way-color-pending-bg: #164FCE;
  --way-color-pending: #E9F2FF;
  --way-color-title-secondary: #FFF;
  --way-color-content-wrapper: #FFFFFF;
  --way-color-separator-mission: #475266;
  --way-color-content-wrapper-mis: #313844;
  --way-bgcolor-badge-counter: #E8ECFA;
  --way-color-badge-couter: #313844;
  --way-color-mission-date-badge: #FFFFFF;
  --way-bgcolor-mission-date-badge: #475266;
  --way-color-title-mission-date: #FFF;
  --way-color-success-bg: #006F63;
  --way-color-success: #FFFFFF;
  --way-color-danger: #FFFFFF;
  --way-color-danger-bg: #AF111C;
  --way-color-brand-alt-l1: #FFFFFF;
  --way-color-brand-l1: #7B438A;
  --way-color-warning-alt: #FFFFFF;
  --way-color-warning-alt-bg: #C69F40;
  --way-color-second-block: #313844;
  --way-step-mission-trajet: var(--way-color-d1);
  --way-second-text-mission-detail: var(--way-color-d1);
  --way-color-third-block: #2F3845;
}

.ion-color-waynium-danger {
  --ion-color-base: var(--ion-color-waynium-danger);
  --ion-color-base-rgb: var(--ion-color-waynium-danger-rgb);
  --ion-color-contrast: var(--ion-color-waynium-danger-contrast);
  --ion-color-contrast-rgb: var(--ion-color-waynium-danger-contrast-rgb);
  --ion-color-shade: var(--ion-color-waynium-danger-shade);
  --ion-color-tint: var(--ion-color-waynium-danger-tint);
}

.ion-color-waynium-warning {
  --ion-color-base: var(--ion-color-waynium-warning);
  --ion-color-base-rgb: var(--ion-color-waynium-warning-rgb);
  --ion-color-contrast: var(--ion-color-waynium-warning-contrast);
  --ion-color-contrast-rgb: var(--ion-color-waynium-warning-contrast-rgb);
  --ion-color-shade: var(--ion-color-waynium-warning-shade);
  --ion-color-tint: var(--ion-color-waynium-warning-tint);
}

.ion-color-waynium-ok {
  --ion-color-base: var(--ion-color-waynium-ok);
  --ion-color-base-rgb: var(--ion-color-waynium-ok-rgb);
  --ion-color-contrast: var(--ion-color-waynium-ok-contrast);
  --ion-color-contrast-rgb: var(--ion-color-waynium-ok-contrast-rgb);
  --ion-color-shade: var(--ion-color-waynium-ok-shade);
  --ion-color-tint: var(--ion-color-waynium-ok-tint);
}

:root ion-grid {
  padding-inline-start: 0;
  padding-inline-end: 0;
}

ion-button,
ion-back-button {
  text-transform: none;
  font-size: 1.23rem;
  font-weight: 600;
  --border-radius: 0.66rem;
}
ion-button.m1,
ion-back-button.m1 {
  color: var(--way-color-d2);
  --background:var(--way-color-m1);
  --ion-toolbar-background: var(--way-color-d2);
  --box-shadow: none;
}
ion-button.grey,
ion-back-button.grey {
  --background: var(--way-style-brand-button2);
  --ion-toolbar-background: var(--ion-color-gray_waynium2);
  --color: var(--ion-color-gray_waynium2);
}
ion-button.grey:active,
ion-back-button.grey:active {
  --background: var(--ion-color-primary-contrast);
  --color: var(--ion-color-gray_waynium);
}
ion-button.m1,
ion-back-button.m1 {
  --background: var(--way-color-m1);
  --color: var(--way-color-d3);
  --ion-toolbar-background: var(--way-color-brand);
  --background-activated: var(--button-color-1);
  --background-focused: var(--button-color-1);
  --background-hover: var(--button-color-1);
}
ion-button.m1.share-button,
ion-back-button.m1.share-button {
  color: var(--way-color-brand);
  --ion-toolbar-background:transparent;
}
ion-button.white,
ion-back-button.white {
  --background: var(--button-color-1);
  --color: var(--ion-text-color);
  --ion-toolbar-background: var(--way-color-brand);
  --background-activated: var(--button-color-1);
  --background-focused: var(--button-color-1);
  --background-hover: var(--button-color-1);
}
ion-button.white.share-button,
ion-back-button.white.share-button {
  color: var(--way-color-brand);
  --ion-toolbar-background:transparent;
}
ion-button.transparent,
ion-back-button.transparent {
  --background: transparent;
  --box-shadow: none;
  --color: inherit;
  --background-activated:transparent;
  --background-focused: transparent;
  --background-hover: transparent;
}
ion-button.outline-grey,
ion-back-button.outline-grey {
  --background: transparent;
  --border-color: var(--way-color-d1);
  --border-width: 1px;
  --border-style: solid;
  --color: --color: var(--way-color-d1);
}
ion-button.outline, ion-button[fill=outline],
ion-back-button.outline,
ion-back-button[fill=outline] {
  --background:transparent;
  --color: var(--way-color-brand);
  --border-color: var(--way-color-brand);
  --border-width: 1px;
  --border-style: solid;
  --box-shadow: none;
}
ion-button.filled, ion-button[fill=filled],
ion-back-button.filled,
ion-back-button[fill=filled] {
  --background: var(--way-style-brand-button);
  --color: var(--ion-color-primary-contrast);
}
ion-button.filled:active, ion-button[fill=filled]:active,
ion-back-button.filled:active,
ion-back-button[fill=filled]:active {
  --background: var(--ion-color-primary-contrast);
  --color: var(--way-style-brand-button);
}

way-partner-signin ion-button.white {
  --background: var(--ion-color-secondary-contrast);
  --color: var(--ion-text-color);
  --ion-toolbar-background: var(--way-color-brand-immutable);
}

ion-button {
  margin: 0;
  height: 40px;
}
ion-button.button-clear, ion-button.button-white {
  font-size: 13px;
  font-weight: 600;
}
ion-button.button-clear {
  --background: transparent;
  --color: var(--way-color-brand);
}
ion-button.button-white {
  --background: #fff;
  --color: #1C1D21;
}

ion-back-button {
  --background: var(--way-color-l1);
  --color: var(--way-color-d2);
}

ion-footer ion-toolbar {
  --background: var(--way-color-l1);
}

.btn-group {
  display: flex;
  align-items: center;
  gap: 0.83rem;
}
.btn-group ion-button,
.btn-group ion-back-button {
  --padding-start: 0;
  --padding-end: 0;
  margin: 0;
  letter-spacing: 0;
  flex-basis: 100%;
  text-align: center;
  height: 40px;
  font-size: 1.16rem;
  font-weight: 600;
}

.footer-toolbar {
  --padding-start: 0;
  --padding-bottom: 0;
  --padding-end: 0;
  --padding-top: 0;
  padding: 1rem 1.33rem !important;
}

form ion-item ion-input, form ion-item ion-textarea, form ion-item ion-select, form ion-item ion-datetime {
  --padding-start: 10px !important;
  --padding-end: 10px !important;
  --padding-top: 10px !important;
  --padding-bottom: 10px !important;
  border-radius: 5px;
  margin-bottom: 10px;
  margin-top: 10px;
}
form ion-item ion-datetime {
  width: 100%;
}
form ion-item ion-select {
  width: 100%;
  max-width: 100%;
}
form ion-item ion-label {
  font-size: 1.5rem;
  font-weight: 500;
}
form ion-item ion-label[position=stacked] {
  margin-bottom: 10px !important;
}
form ion-item ion-label[position=fixed] {
  min-width: 200px !important;
}

hr.separator {
  padding: 0 0.8em;
  opacity: 0.3;
  width: 90%;
}
hr.separator.dashed {
  border-top: 1px dashed var(--way-style-block-border);
}

.distance,
.time {
  background-color: var(--way-color-l2);
}

:root remix-icon {
  display: block;
}
:root remix-icon.size-xl {
  width: 26px;
  height: 26px;
}
:root remix-icon.size-lg {
  width: 22px;
  height: 22px;
}
:root remix-icon.size-md {
  width: 20px;
  height: 20px;
}
:root remix-icon.size-sm {
  width: 17px;
  height: 17px;
}
:root remix-icon.size-xs {
  width: 13px;
  height: 13px;
}

ion-range.best-price {
  --knob-size: 20px;
  --knob-background: white;
  --knob-box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
  --bar-background-active: transparent;
  --bar-background: linear-gradient(270deg, #2ddf00 0%, #ffe600 50%, #ff0000 100%), #c4c4c4;
  --bar-height: 10px;
  --bar-border-radius: 4px;
}

ion-input,
ion-select,
ion-datetime,
ion-textarea,
ion-checkbox {
  --checkmark-color: var(--ion-background-color);
  background: var(--ion-background-color);
  border-radius: 5px;
  box-shadow: 0px 2px 6px rgba(34, 26, 56, 0.05);
  --padding-start: 16px !important;
}

way-partner-signin ion-input {
  background: var(--ion-color-secondary-contrast) !important;
}

ion-datetime {
  color: var(--way-style-txt-primary);
}

ion-input[type=date].ios:not(.has-value) > input::before,
ion-input[type=time].ios:not(.has-value) > input::before {
  content: attr(placeholder);
  color: var(--placeholder-color);
  opacity: var(--placeholder-opacity);
}

ion-content.secondary-background {
  --background: var(--secondary-background-color);
}

ion-card.content-card {
  --background: var(--way-style-blockbg);
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.02), 0 2px 2px 0 rgba(0, 0, 0, 0.01), 0 1px 5px 0 rgba(0, 0, 0, 0.01);
  -webkit-box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.02), 0 2px 2px 0 rgba(0, 0, 0, 0.01), 0 1px 5px 0 rgba(0, 0, 0, 0.01);
  border-radius: 3px;
  -webkit-margin-start: 0;
  margin-inline-start: 0;
  -webkit-margin-end: 0;
  margin-inline-end: 0;
}

:root .mission-calendar .cal-month-view .cal-day-cell,
:root .mission-calendar .cal-month-view .cal-cell-row:hover,
:root .mission-calendar .cal-month-view .cal-cell-row .cal-cell:hover,
:root .mission-calendar .cal-month-view .cal-cell.cal-has-events.cal-open,
:root .mission-calendar .cal-month-view {
  background-color: transparent;
}
:root .mission-calendar .cal-month-view .cal-day-cell {
  min-height: 0;
}
:root .mission-calendar .cal-month-view .cal-days,
:root .mission-calendar .cal-month-view .cal-days .cal-cell-row,
:root .mission-calendar .cal-month-view .cal-day-cell:not(:last-child) {
  border: none;
}
:root .mission-day-view {
  width: 100%;
}
:root .mission-day-view .cal-week-view .cal-hour:not(:last-child) .cal-hour-segment,
:root .mission-day-view .cal-week-view .cal-hour:last-child :not(:last-child) .cal-hour-segment {
  border: none;
}
:root .mission-day-view .cal-hour,
:root .mission-day-view .cal-week-view .cal-time-events .cal-day-columns:not(.cal-resize-active) .cal-hour-segment:hover {
  background-color: var(--way-color-l1);
}
:root .mission-day-view .cal-hour:not(:last-child) {
  border-bottom: 1px solid var(--way-color-m1);
}
:root .mission-day-view .cal-event,
:root .mission-day-view .cal-week-view {
  border: none;
}
:root .mission-day-view .cal-event {
  color: #fff;
  border-radius: 0.66rem;
  padding: 0.5rem 1rem;
  min-height: 52px;
  min-width: 65px;
  box-shadow: 0px 2px 5px -1px rgba(0, 0, 0, 0.05);
}
:root .mission-day-view .cal-event.pastNotClosed {
  background-color: var(--way-color-past-not-closed);
}
:root .mission-day-view .cal-event.transfert {
  background-color: var(--way-color-transfert);
}
:root .mission-day-view .cal-event.disposal {
  color: var(--way-color-n2);
  background-color: var(--way-color-disposal);
}
:root .mission-day-view .cal-event.airportPickUp {
  background-color: var(--way-color-airport-pickup);
}
:root .mission-day-view .cal-event.airportDropOff {
  background-color: var(--way-color-airport-dropoff);
}
:root .mission-day-view .cal-day-view .cal-events-container {
  margin: 0 0.25rem;
}
:root .mission-day-view .cal-time {
  position: absolute;
  left: -20%;
  top: -25%;
}
:root .mission-day-view .cal-week-view .cal-time-events {
  border: 1px solid var(--way-color-m1);
  width: calc(100% - 40px);
  margin-left: auto;
}
:root .mission-day-view .custom-hour.cal-cell-row.cal-header {
  overflow: visible;
}
:root .mission-day-view .cal-week-view .cal-time-events .cal-event {
  line-height: normal;
}

.pulse-animation {
  position: relative;
}

.pulse-animation:before,
.pulse-animation:after,
.pulse-animation-inner {
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  opacity: 0;
  top: 0%;
  left: 0%;
  border-radius: 50%;
}

.quick-waves:before,
.quick-waves:after,
.quick-waves .pulse-animation-inner {
  opacity: 0;
  animation: glow-grow 1.8s cubic-bezier(0.4, 0, 0.2, 1) infinite;
  border: 1px solid rgba(255, 255, 255, 0.4);
  box-shadow: 0px 0px 30px 0px rgba(255, 255, 255, 0.2) inset;
}

.quick-waves:after {
  animation-delay: 0.6s;
}

.quick-waves .pulse-animation-inner {
  animation-delay: 1.2s;
}

.slow-waves:before,
.slow-waves:after,
.slow-waves .pulse-animation-inner {
  animation: glow-grow-small 3s cubic-bezier(0.4, 0, 0.2, 1) infinite;
  border: 1px solid rgba(255, 255, 255, 0.12);
  box-shadow: 0px 0px 10px 0px rgba(255, 255, 255, 0.1) inset;
}

.slow-waves:after {
  animation-delay: 1s;
}

.slow-waves .pulse-animation-inner {
  animation-delay: 2s;
}

@keyframes glow-grow {
  0% {
    opacity: 0;
    transform: scale(1);
  }
  50% {
    opacity: 0.6;
  }
  100% {
    transform: scale(2.5);
    opacity: 0;
  }
}
@keyframes glow-grow-small {
  0% {
    opacity: 0;
    transform: scale(1);
  }
  50% {
    opacity: 0.4;
  }
  100% {
    transform: scale(1.8);
    opacity: 0;
  }
}