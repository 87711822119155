ion-button,
ion-back-button {
  text-transform: none;
  font-size: 1.23rem;
  font-weight: 600;
  --border-radius: 0.66rem;

  &.m1 {
    color:var(--way-color-d2);
    --background:var(--way-color-m1);
    --ion-toolbar-background: var(--way-color-d2);
    --box-shadow: none;
  }

  &.grey {
    --background: var(--way-style-brand-button2);
    --ion-toolbar-background: var(--ion-color-gray_waynium2);
    --color: var(--ion-color-gray_waynium2);

    &:active {
      --background: var(--ion-color-primary-contrast);
      --color:  var(--ion-color-gray_waynium);
    }
  }

  &.m1 {
    --background: var(--way-color-m1);
    --color: var(--way-color-d3);
    --ion-toolbar-background: var(--way-color-brand);
      &.share-button{
        color: var(--way-color-brand);
        --ion-toolbar-background:transparent;
      }
    --background-activated: var(--button-color-1);
    --background-focused: var(--button-color-1);
    --background-hover: var(--button-color-1);
  }

  &.white {
    --background: var(--button-color-1);
    --color: var(--ion-text-color);
    --ion-toolbar-background: var(--way-color-brand);
      &.share-button{
        color: var(--way-color-brand);
        --ion-toolbar-background:transparent;
      }
    --background-activated: var(--button-color-1);
    --background-focused: var(--button-color-1);
    --background-hover: var(--button-color-1);
  }

  &.transparent {
    --background: transparent;
    --box-shadow: none;
    --color: inherit;
    --background-activated:transparent;
    --background-focused: transparent;
    --background-hover: transparent;
  }

  &.outline-grey {
    --background: transparent;
    --border-color: var(--way-color-d1);
    --border-width: 1px;
    --border-style: solid;
    --color: --color: var(--way-color-d1); 
  }

  &.outline,
  &[fill='outline'] {
    --background:transparent;
    --color: var(--way-color-brand);
    --border-color: var(--way-color-brand);
    --border-width: 1px;
    --border-style: solid;
    --box-shadow: none;
  }

  &.filled,
  &[fill='filled'] {
    --background: var(--way-style-brand-button);
    --color: var(--ion-color-primary-contrast);

    &:active {
      --background: var(--ion-color-primary-contrast);
      --color: var(--way-style-brand-button);
    }
  }
}

way-partner-signin ion-button.white{
  --background: var(--ion-color-secondary-contrast);
  --color: var(--ion-text-color);
  --ion-toolbar-background: var(--way-color-brand-immutable);
}

ion-button {
  margin: 0;
  height: 40px;

  &.button-clear,
  &.button-white {
    font-size: 13px;
    font-weight: 600;
  }

  &.button-clear {
    --background: transparent;
    --color: var(--way-color-brand);
  }
  &.button-white {
    --background: #fff;
    --color: #1C1D21;
  }
  
}

ion-back-button {
  --background: var(--way-color-l1);
  --color: var(--way-color-d2);
}

ion-footer {
  ion-toolbar {
    --background: var(--way-color-l1);
  }
}

.btn-group {
  display: flex;
  align-items: center;
  gap:0.83rem;

  ion-button,
  ion-back-button {
    --padding-start: 0;
    --padding-end: 0;
    margin: 0;
    letter-spacing: 0;
    flex-basis: 100%;
    text-align: center;
    height: 40px;
    font-size: 1.16rem;
    font-weight: 600;
  }
}


.footer-toolbar {
  --padding-start: 0;
  --padding-bottom: 0;
  --padding-end: 0;
  --padding-top: 0;
  padding: 1rem 1.33rem !important;
}