/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import '~@ionic/angular/css/core.css';

/* Basic CSS for apps built with Ionic */
@import '~@ionic/angular/css/normalize.css';
@import '~@ionic/angular/css/structure.css';
@import '~@ionic/angular/css/typography.css';
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import '~@ionic/angular/css/padding.css';
@import '~@ionic/angular/css/float-elements.css';
@import '~@ionic/angular/css/text-alignment.css';
@import '~@ionic/angular/css/text-transformation.css';
@import '~@ionic/angular/css/flex-utils.css';

@import '~leaflet/dist/leaflet.css';
/* Add application styles & imports to this file! */
@import '~intl-tel-input/build/css/intlTelInput.css';
@import '../../../node_modules/angular-calendar/css/angular-calendar.css';

:root {
  --color-light-gray: #6c769a;
  --color-light-blue-bg: #f7f8ff;
  --color-purple: #491057;
}
@media (prefers-color-scheme: dark) {
  :root {
    --color-light-gray: #a8b5e6;
  }
}
:root {
  --ion-color-blue_wayd: #12489a;
  --ion-color-blue_wayd-rgb: 18, 72, 154;
  --ion-color-blue_wayd-contrast: #ffffff;
  --ion-color-blue_wayd-contrast-rgb: 255, 255, 255;
  --ion-color-blue_wayd-shade: #103f88;
  --ion-color-blue_wayd-tint: #2a5aa4;
}

.ion-color-blue_wayd {
  --ion-color-base: var(--ion-color-blue_wayd);
  --ion-color-base-rgb: var(--ion-color-blue_wayd-rgb);
  --ion-color-contrast: var(--ion-color-blue_wayd-contrast);
  --ion-color-contrast-rgb: var(--ion-color-blue_wayd-contrast-rgb);
  --ion-color-shade: var(--ion-color-blue_wayd-shade);
  --ion-color-tint: var(--ion-color-blue_wayd-tint);
}

:root {
  --ion-color-white_wayd: #ffffff;
  --ion-color-white_wayd-rgb: 255, 255, 255;
  --ion-color-white_wayd-contrast: #000000;
  --ion-color-white_wayd-contrast-rgb: 0, 0, 0;
  --ion-color-white_wayd-shade: #e0e0e0;
  --ion-color-white_wayd-tint: #ffffff;
}

.ion-color-white_wayd {
  --ion-color-base: var(--ion-color-white_wayd);
  --ion-color-base-rgb: var(--ion-color-white_wayd-rgb);
  --ion-color-contrast: var(--ion-color-white_wayd-contrast);
  --ion-color-contrast-rgb: var(--ion-color-white_wayd-contrast-rgb);
  --ion-color-shade: var(--ion-color-white_wayd-shade);
  --ion-color-tint: var(--ion-color-white_wayd-tint);
}

:root {
  --ion-color-gray_waynium: #2f3845;
  --ion-color-gray_waynium-rgb: 47, 56, 69;
  --ion-color-gray_waynium-contrast: #ffffff;
  --ion-color-gray_waynium-contrast-rgb: 255, 255, 255;
  --ion-color-gray_waynium-shade: #29313d;
  --ion-color-gray_waynium-tint: #444c58;
}

.ion-color-gray_waynium {
  --ion-color-base: var(--ion-color-gray_waynium);
  --ion-color-base-rgb: var(--ion-color-gray_waynium-rgb);
  --ion-color-contrast: var(--ion-color-gray_waynium-contrast);
  --ion-color-contrast-rgb: var(--ion-color-gray_waynium-contrast-rgb);
  --ion-color-shade: var(--ion-color-gray_waynium-shade);
  --ion-color-tint: var(--ion-color-gray_waynium-tint);
}

:root {
  --ion-color-way-bg: #f6f8ff;
  --ion-color-way-bg-rgb: 246, 248, 255;
  --ion-color-way-bg-contrast: #000000;
  --ion-color-way-bg-contrast-rgb: 0, 0, 0;
  --ion-color-way-bg-shade: #d8dae0;
  --ion-color-way-bg-tint: #f7f9ff;
}

.ion-color-way-bg {
  --ion-color-base: var(--ion-color-way-bg);
  --ion-color-base-rgb: var(--ion-color-way-bg-rgb);
  --ion-color-contrast: var(--ion-color-way-bg-contrast);
  --ion-color-contrast-rgb: var(--ion-color-way-bg-contrast-rgb);
  --ion-color-shade: var(--ion-color-way-bg-shade);
  --ion-color-tint: var(--ion-color-way-bg-tint);
}

:root {
  --ion-color-way-darker: #f6f8ff;
  --ion-color-way-darker-rgb: 232, 236, 250;
  --ion-color-way-darker-contrast: #000000;
  --ion-color-way-darker-contrast-rgb: 0, 0, 0;
  --ion-color-way-darker-shade: #ccd0dc;
  --ion-color-way-darker-tint: #eaeefb;
}

.ion-color-way-darker {
  --ion-color-base: var(--ion-color-way-darker);
  --ion-color-base-rgb: var(--ion-color-way-darker-rgb);
  --ion-color-contrast: var(--ion-color-way-darker-contrast);
  --ion-color-contrast-rgb: var(--ion-color-way-darker-contrast-rgb);
  --ion-color-shade: var(--ion-color-way-darker-shade);
  --ion-color-tint: var(--ion-color-way-darker-tint);
}

:root {
  --ion-color-way-partner: #491057;
  --ion-color-way-partner-rgb: 73, 16, 87;
  --ion-color-way-partner-contrast: #ffffff;
  --ion-color-way-partner-contrast-rgb: 255, 255, 255;
  --ion-color-way-partner-shade: #400e4d;
  --ion-color-way-partner-tint: #5b2868;
}

.ion-color-way-partner {
  --ion-color-base: var(--ion-color-way-partner);
  --ion-color-base-rgb: var(--ion-color-way-partner-rgb);
  --ion-color-contrast: var(--ion-color-way-partner-contrast);
  --ion-color-contrast-rgb: var(--ion-color-way-partner-contrast-rgb);
  --ion-color-shade: var(--ion-color-way-partner-shade);
  --ion-color-tint: var(--ion-color-way-partner-tint);
}

:root {
  --ion-color-way-dark: #6c769a;
  --ion-color-way-dark-rgb: 108, 118, 154;
  --ion-color-way-dark-contrast: #ffffff;
  --ion-color-way-dark-contrast-rgb: 255, 255, 255;
  --ion-color-way-dark-shade: #5f6888;
  --ion-color-way-dark-tint: #7b84a4;
}

.ion-color-way-dark {
  --ion-color-base: var(--ion-color-way-dark);
  --ion-color-base-rgb: var(--ion-color-way-dark-rgb);
  --ion-color-contrast: var(--ion-color-way-dark-contrast);
  --ion-color-contrast-rgb: var(--ion-color-way-dark-contrast-rgb);
  --ion-color-shade: var(--ion-color-way-dark-shade);
  --ion-color-tint: var(--ion-color-way-dark-tint);
}

.px-4 {
  padding-left: 30px;
  padding-right: 30px;
}

.flex {
  display: flex;
}

.flex-col {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.items-center {
  align-items: center;
}

.items-baseline {
  align-items: baseline;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.self-baseline {
  align-self: baseline;
}

.text-no-wrap {
  white-space: nowrap;
}

$spacing-xs: 0.33rem;
$spacing-sm: 0.66rem;
$spacing-md: 1rem;
$spacing-lg: 1.33rem;
$spacing-xl: 1.66rem;
$spacing-xxl: 2rem;

// Gap classes
.gap-l {
  gap: $spacing-lg;
}

.gap-md {
  gap: $spacing-md;
}

.gap {
  gap: $spacing-sm;
}

.gap-xs {
  gap: $spacing-xs;
}

.gap-xl {
  gap: $spacing-xl;
}

.gap-xxl {
  gap: $spacing-xxl;
}

.py-lg {
  padding: $spacing-lg 0;
}

.mb-xxl {
  margin-bottom: $spacing-xxl;
}

.mb {
  margin-bottom: $spacing-md;
}

.mb-lg {
  margin-bottom: $spacing-lg;
}

.mb-sm {
  margin-bottom: $spacing-sm;
}

.mb-xs {
  margin-bottom: $spacing-xs;
}

.mb-lg {
  margin-bottom: $spacing-lg;
}

.mt-xs {
  margin-top: $spacing-xs;
}

.mt {
  margin-top: $spacing-sm;
}

.mt-md {
  margin-top: $spacing-md;
}

.mr {
  margin-right: $spacing-sm;
}

.mr-xs {
  margin-right: $spacing-xs;
}

.ml {
  margin-left: $spacing-sm;
}

.pb-xs {
  padding-bottom: $spacing-xs;
}

.pb-md {
  padding-bottom: $spacing-md;
}

.margin-none {
  margin: 0;
}

.h-full {
  height: 100%;
}

.w-full {
  width: 100%;
}

.shadow-none {
  box-shadow: none !important;
}

.logo_margin {
  margin-top: 40px;
}

.bold-title {
  font-weight: 700;
  font-size: 18px;
  color: var(--light-theme-d-2);
}

.title {
  color: var(--way-color-brand);
  font-size: 16px;
  text-align: center;
}

.subtitle {
  color: var(--way-color-d1);
}

.select {
  border-radius: 0.66rem;
  padding: 1rem;
  height: 40px;
  border: 1px solid var(--way-color-m2);
  background: var(--way-color-l1);
}

.align-left {
  text-align: left;
}

ion-input {
  &.sc-ion-input-md-h:not(.legacy-input),
  &.sc-ion-input-ios-h:not(.legacy-input) {
    min-height: 34px;
  }
  .sc-ion-input-ios-s > [slot='start']:last-of-type {
    margin-inline-end: 0.66rem;
  }
}

ion-textarea {
  &.sc-ion-textarea-ios-h:not(.legacy-textarea) {
    min-height: 0;
  }
}

ion-input,
ion-textarea {
  --highlight-color: none !important;
}

ion-select {
  width: 100%;
  min-height: 34px;

  &::part(icon) {
    position: absolute;
    right: 8px;
  }

  &::part(text) {
    flex: 1;
    padding-right: 24px;
  }

  &::part(container) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
}

.input-container {
  background-color: transparent;
  position: relative;
  border: 1px solid var(--way-color-m2);
  border-radius: 0.66rem;
  padding: 0.2rem 0.33rem 0.2rem 1rem;
  min-height: 40px;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 0.66rem;
  overflow: hidden;

  &.has-error {
    border: 1px solid var(--way-color-danger);

    .input-label {
      color: var(--way-color-danger);
    }
  }

  &.has-label {
    overflow: initial;
  }

  &.no-icon {
    padding: 0.2rem 0.33rem 0.2rem 1rem;
  }

  &.textarea-container {
    padding: 1rem;

    ion-textarea {
      --padding-top: 0;
      --padding-bottom: 0;
      --padding-start: 0 !important;
      --padding-end: 0;
    }
  }

  &.variant-signup {
    max-height: 40px;

    ion-input {
      --placeholder-color: var(--way-color-d1);
      height: 36px;
    }
  }

  ion-select,
  ion-input {
    --padding-start: 0.66rem !important;
  }

  ion-input {
    tabler-icon[slot='start'] {
      margin-inline-end: 0.66rem;
    }

    label {
      --padding-start: 0;
    }
  }

  ion-textarea,
  ion-select,
  ion-input {
    box-shadow: none;
    --placeholder-color: var(--way-color-d2);
    --placeholder-opacity: 1;
    --placeholder-font-weight: 500;

    &.disabled {
      pointer-events: none;
    }
  }

  ion-textarea,
  ion-select {
    width: 100%;
    font-weight: 500;
    font-size: 1.1rem;
    color: var(--way-color-d2);
  }

  ion-select,
  ion-datetime-button {
    display: none;
  }

  tabler-icon[icon='chevron-down'] {
    margin-left: auto;
  }

  .input-value {
    color: var(--way-color-d2);
    font-size: 1.16rem;
    font-weight: 500;
  }

  .input-label {
    color: var(--way-color-d1);
    font-weight: 500;
    line-height: 15px;
    position: absolute;
    top: -9px;
    left: 7px;
    background-color: var(--way-input-label-color);
    z-index: 3;
    padding: 0 0.33rem;
  }
}

.badge-container {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  background-color: var(--way-color-m1);
  border-radius: 2.66rem;
  padding: 0 0.33rem;
}

ion-button {
  min-height: 0;
}

.form-error {
  padding-top: 0.33rem;
  color: var(--way-color-d1);
}

.checkbox-container {
  display: flex;
  align-items: center;
  gap: 1rem;

  ion-checkbox {
    --border-color: var(--way-color-d1);
  }

  ion-label {
    font-weight: 600;
  }
}

.accordion-content,
.accordion-header,
.accordion-title-container,
.accordion-header-top {
  display: flex;
}

ion-accordion-group {
  margin: 0;

  &.accordion-disabled {
    pointer-events: none;
  }
}

ion-accordion {
  padding: 1rem;
  border: 1px solid var(--ion-accordion-color);
}

.accordion-title-container,
.accordion-header-top {
  align-items: center;
}

.accordion-header {
  flex-direction: column;
  gap: 0.66rem;
}

.accordion-header-top,
.accordion-header-bottom {
  width: 100%;
}

.accordion-header-top {
  justify-content: space-between;

  tabler-icon[icon='chevron-right'] {
    &.opened {
      transform: rotate(90deg);
    }
  }
}

.accordion-header-bottom,
.accordion-header-details {
  font-size: 1.16rem;
  font-weight: 500;
}

.accordion-header-bottom {
  border-top: 1px solid var(--way-color-m1-immutable);
  padding-top: 0.66rem;

  p {
    margin: 0;
  }
}

.accordion-title-container {
  gap: 0.66rem;

  ion-label {
    text-wrap: nowrap;
  }
}

.accordion-content {
  border-top: 1px solid var(--ion-accordion-color);
  padding-top: 1rem;
  margin-top: 1rem;
  flex-direction: column;
  gap: 1rem;

  form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
}

.custom-modal {
  --height: auto;
  --width: 240px;
  --overflow: scroll;
  --min-height: 45px;
  --max-height: 250px;
  --border-radius: 0.66rem;
  --box-shadow: 0px 4px 64px 0px rgba(38, 46, 56, 0.12);

  &.small {
    --width: 160px;
  }
}

.wrong-siret-alert-modal {
  --backdrop-opacity: 0.7;

  .cancel-btn {
    color: var(--way-color-m4);
    font-weight: 600;
  }
}

.custom-time-changed-alert-modal {
  --background: #ffffff;
  --border-radius: 12px;
  --box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15);
  --width: auto;
  --max-width: 90%;
  padding: 20px;

  .alert-content {
    text-align: center;

    .alert-title {
      font-size: 1.5rem;
      font-weight: bold;
      color: #333;
      margin-bottom: 10px;
    }

    .alert-location {
      font-size: 1.1em;
      color: #555;
      margin-bottom: 15px;
    }

    .alert-time {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 1.6rem;
      font-weight: 600;

      ion-icon,
      .prev-time {
        color: #555;
      }

      ion-icon {
        font-size: 1.6rem;
        margin: 0 10px;
      }

      .new-time {
        color: var(--way-color-d2-immutable);
      }
    }
  }

  .alert-button-group {
    display: flex;
    justify-content: space-around;

    .alert-button {
      --background: transparent;
      --border-radius: 8px;
      --padding: 10px 20px;
    }

    .confirm-button {
      --color: #ffffff;
      --border-radius: 8px;
    }

    .cancel-button {
      --background: #e0e0e0;
      --color: #333;
      --border-radius: 8px;
    }
  }
}

.send-all-partners-alert-modal {
  .send-partner-alert-content {
    p {
      font-size: 1.1em;
      color: #555;
    }

    .bold {
      font-weight: bold;
    }
  }
}

.shepherd-theme-custom.shepherd-has-title {
  width: 27rem;
  padding: 1rem;

  &.sheperd-arrow-right {
    .shepherd-arrow {
      left: unset !important;
      right: 2%;
    }
  }

  .shepherd-arrow::before {
    background-color: #fff !important;
  }

  .shepherd-content {
    .shepherd-header {
      background-color: #fff;
      padding: 0;
      margin-bottom: 0.33rem;

      .shepherd-title {
        color: var(--way-color-brand-immutable);
        font-size: 1.5rem;
        font-weight: 600;
      }
    }

    .shepherd-text {
      padding: 0;
      color: var(--way-color-brand-immutable);
      font-size: 1.16667rem;

      .lottie-container-swipe-left,
      .lottie-container-swipe-up {
        position: absolute;
        width: 45px;
        height: 45px;
        filter: invert(1);
        left: 50%;
        transform: translateX(-50%);
      }

      .lottie-container-swipe-up {
        bottom: -48%;
      }

      .lottie-container-swipe-left {
        bottom: -54%;
      }
    }

    .shepherd-footer {
      padding: 1rem 0 0 0;
      justify-content: flex-start;

      .shepherd-button {
        display: flex;
        height: 2rem;
        padding: 0rem 0.66667rem;
        justify-content: center;
        align-items: center;
        font-size: 1rem;
        font-weight: 600;
        gap: 0.33333rem;
        border-radius: 0.33333rem;
        color: var(--way-color-brand-immutable);
        border: 1px solid var(--way-color-brand-immutable);
        background: #fff;
      }
    }
  }
}

.shepherd-touch-bottom-alt-2,
.shepherd-touch-top-right,
.shepherd-touch-top,
.shepherd-touch-bottom,
.shepherd-touch-right,
.shepherd-touch-bottom-alt {
  position: relative;

  &::after {
    content: '';
    z-index: 9;
    display: block;
    width: 24px;
    height: 24px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3E%3Ccircle cx='12' cy='12' r='12' fill='%23CF79F7' fill-opacity='0.48'/%3E%3Ccircle cx='12' cy='12' r='6' fill='%23F7EFF9'/%3E%3C/svg%3E");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
  }
}

.shepherd-touch-top,
.shepherd-touch-bottom,
.shepherd-touch-bottom-alt {
  &::after {
    left: 50%;
    transform: translateX(-50%);
  }
}

.shepherd-touch-right,
.shepherd-touch-bottom {
  &::after {
    bottom: -10px;
  }
}

.shepherd-touch-right {
  &::after {
    right: 0;
  }
}

.shepherd-touch-top {
  &::after {
    top: 2px;
  }
}

.shepherd-touch-bottom-alt {
  &::after {
    bottom: 3px;
  }
}

.shepherd-touch-bottom-alt-2 {
  &::after {
    bottom: -3px;
  }
}

.shepherd-touch-top-right {
  &::after {
    top: -5px;
    right: 10px;
  }
}

.loading-screen {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 300px;
}

.bottom-toolbar {
  box-shadow: 0px -4px 16px 0px rgba(0, 0, 0, 0.08);
  padding: 0 0.33rem 1rem 0.33rem;

  &.ios {
    padding: 0 0 1.5rem 0;
  }
}

.shepherd-theme-custom.shepherd-has-title {
  width: 27rem;
  padding: 1rem;

  .shepherd-arrow::before {
    background-color: #fff !important;
  }

  .shepherd-content {
    .shepherd-header {
      background-color: #fff;
      padding: 0;
      margin-bottom: 0.33rem;

      .shepherd-title {
        color: var(--way-color-brand-immutable);
        font-size: 1.5rem;
        font-weight: 600;
      }
    }

    .shepherd-text {
      padding: 0;
      color: var(--way-color-brand-immutable);
      font-size: 1.16667rem;
    }

    .shepherd-footer {
      padding: 1rem 0 0 0;
      justify-content: flex-start;

      .shepherd-button {
        display: flex;
        height: 2rem;
        padding: 0rem 0.66667rem;
        justify-content: center;
        align-items: center;
        font-size: 1rem;
        font-weight: 600;
        gap: 0.33333rem;
        border-radius: 0.33333rem;
        color: var(--way-color-brand-immutable);
        border: 1px solid var(--way-color-brand-immutable);
        background: #fff;
      }
    }
  }
}

.fade-in-bottom {
  animation: fade-in-bottom 400ms ease-in-out forwards;
}

.fade-in-bottom-2 {
  animation: fade-in-bottom-2 300ms ease-in-out forwards;
}

.fade-in {
  animation: fade-in 400ms ease-in-out forwards;
}

@keyframes fade-in-bottom {
  0% {
    transform: translateY(10px);
    opacity: 0;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}

@keyframes fade-in-bottom-2 {
  0% {
    transform: translateY(4px);
    opacity: 0;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
