.pulse-animation {
  position: relative;
}

.pulse-animation:before,
.pulse-animation:after,
.pulse-animation-inner {
  position: absolute;
  content: '';
  height: 100%;
  width: 100%;
  opacity: 0;
  top: 0%;
  left: 0%;
  border-radius: 50%;
}

.quick-waves:before,
.quick-waves:after,
.quick-waves .pulse-animation-inner {
  opacity: 0;
  animation: glow-grow 1.8s cubic-bezier(0.4, 0, 0.2, 1) infinite;
  border: 1px solid rgba(255, 255, 255, 0.4);
  box-shadow: 0px 0px 30px 0px rgba(255, 255, 255, 0.2) inset;
}

.quick-waves:after {
  animation-delay: 0.6s;
}

.quick-waves .pulse-animation-inner {
  animation-delay: 1.2s;
}

.slow-waves:before,
.slow-waves:after,
.slow-waves .pulse-animation-inner {
  animation: glow-grow-small 3s cubic-bezier(0.4, 0, 0.2, 1) infinite;
  border: 1px solid rgba(255, 255, 255, 0.12);
  box-shadow: 0px 0px 10px 0px rgba(255, 255, 255, 0.1) inset;
}

.slow-waves:after {
  animation-delay: 1s;
}

.slow-waves .pulse-animation-inner {
  animation-delay: 2s;
}

@keyframes glow-grow {
  0% {
    opacity: 0;
    transform: scale(1);
  }
  50% {
    opacity: 0.6;
  }
  100% {
    transform: scale(2.5);
    opacity: 0;
  }
}

@keyframes glow-grow-small {
  0% {
    opacity: 0;
    transform: scale(1);
  }
  50% {
    opacity: 0.4;
  }
  100% {
    transform: scale(1.8);
    opacity: 0;
  }
}
