// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
  --ion-background-color: var(--way-style-blockbg);
  --ion-background-color-rgb: 255, 255, 255;
  --secondary-background-color: var(--way-style-pagebg);

  --ion-text-color: var(--way-color-d2);
  --ion-text-color-rgb: 47, 56, 69;

  --ion-border-color: transparent;
  --ion-toolbar-background: var(--way-style-blockbg);
  --ion-item-background: transparent;

  /** primary **/
  --ion-color-primary: var(--way-style-brand);
  --ion-color-primary-rgb: 42, 90, 164;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #103f88;
  --ion-color-primary-tint: #2a5aa4;

  /** secondary **/
  --ion-color-secondary: var(--way-color-brand);
  --ion-color-secondary-rgb: 61, 194, 255;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #36abe0;
  --ion-color-secondary-tint: #50c8ff;

  /** tertiary **/
  --ion-color-tertiary: #5260ff;
  --ion-color-tertiary-rgb: 82, 96, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #4854e0;
  --ion-color-tertiary-tint: #6370ff;

  /** success **/
  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  /** warning **/
  --ion-color-warning: #ff9900;
  --ion-color-warning-rgb: 255, 153, 0;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e08700;
  --ion-color-warning-tint: #ffa31a;

  /** danger **/
  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  /** waynium danger **/
  --ion-color-waynium-danger: #fa2f02;
  --ion-color-waynium-danger-rgb: 250, 47, 2;
  --ion-color-waynium-danger-contrast: #ffffff;
  --ion-color-waynium-danger-contrast-rgb: 255, 255, 255;
  --ion-color-waynium-danger-shade: #dc2902;
  --ion-color-waynium-danger-tint: #fb441b;

  /** waynium warning **/
  --ion-color-waynium-warning: #ff9900;
  --ion-color-waynium-warning-rgb: 255, 153, 0;
  --ion-color-waynium-warning-contrast: #000000;
  --ion-color-waynium-warning-contrast-rgb: 0, 0, 0;
  --ion-color-waynium-warning-shade: #e08700;
  --ion-color-waynium-warning-tint: #ffa31a;

  /** waynium ok **/
  --ion-color-waynium-ok: #54a900;
  --ion-color-waynium-ok-rgb: 84, 169, 0;
  --ion-color-waynium-ok-contrast: #ffffff;
  --ion-color-waynium-ok-contrast-rgb: 255, 255, 255;
  --ion-color-waynium-ok-shade: #4a9500;
  --ion-color-waynium-ok-tint: #65b21a;

  /** waynium mid #1 **/
  --ion-color-waynium-light1: #d0d4e5;

  /** WAYNIUM PALETTE **/

  /** waynium brand */
  --way-color-brand: #491057;

  /** waynium light #1 */
  --way-color-l1: #ffffff;

  /** waynium light #2 */
  --way-color-l2: #f6f8ff;

  /** waynium mid #1 */
  --way-color-m1: #e8ecfa;

  /** waynium mid #2 */
  --way-color-m2: #d0d4e5;

  /** waynium mid #2 */
  --way-color-m3: #a3acce;

  /** waynium dark #1 */
  --way-color-d1: #6c769a;

  /** waynium dark #2 */
  --way-color-d2: #2f3845;

  --way-color-n2: #15191f;

  /********** COLOR STYLES **********/

  /* Page background **/
  --way-style-pagebg: var(--way-color-l2);

  /* Block background **/
  --way-style-blockbg: var(--way-color-l1);

  /** Brand **/
  --way-style-brand: var(--way-color-brand);

  --way-style-brand-contrast: var(--way-color-l1);

  --way-style-brand-block-d1: var(--way-color-brand);

  --way-style-brand-block-d2: #e5e9f6;

  --way-style-brand-block-d3: #fff;

  --way-style-brand-block-d4: #491057;

  --way-style-block: var(--way-color-l1);

  --way-style-brand-button: var(--way-color-brand);

  --way-style-brand-button2: var(--way-color-m1-immutable);

  --ion-color-gray_waynium2: var(--ion-color-gray_waynium);

  --color-stepper-container-p: #221a38;

  --ion-toggle-bg: #d0d4e5;

  --way-color-p-signin: #d0d4e5;

  --way-style-brand-d1: #2f1436;

  --way-style-brand-d2: #1a0e1d;

  --way-color-brand-immutable: #491057;

  --way-color-brand-alt: var(--way-color-brand);

  --way-color-brand-l1: #f7eff9;

  --way-color-brand-l2: #e5ceec;

  /* Primary text **/
  --way-style-txt-primary: var(--way-color-d2);

  /* Secondary text **/
  --way-style-txt-secondary: var(--way-color-d1);

  /* Tertiary text **/
  --way-style-txt-tertiary: var(--way-color-d2);

  /* Borders & separators **/
  --way-style-block-border: var(--way-color-m1);
  --way-style-page-border: var(--way-color-m2);

  /* Box shadow **/
  --way-style-box-shadow: 0px 2px 7px -3px #48484837;

  /* danger status **/
  --way-style-danger-bg: #fcf1ee;

  /* warning status **/
  --way-style-warning-bg: #fff8e5;

  /* success status **/
  --way-style-success-bg: #e9f6db;

  /* Immutable */

  --way-color-m1-immutable: #e8ecfa;

  --way-color-d1-immutable: #6c769a;

  --way-color-d2-immutable: #2f3845;

  --way-color-danger: #c73131;

  --way-color-danger-bg: #ffecea;

  --way-color-success: #1b8b7e;

  --way-color-success-bg: #eaf5f2;

  --way-style-radio-container-disabled-bg: var(--way-color-l2);
  --way-style-radio-container-disabled-color: var(--way-color-d1);

  --button-color-1: #ffffff;
  --button-color-2: var(--way-color-brand-immutable);
  --button-color-3: var(--way-color-brand-immutable);
  --way-optional-label: #6c769a;
  --way-input-label-color: #ffffff;
  --way-symbol-color: #2f3845;
  --ion-chip-select: #ffffff;
  --ion-accordion-color: var(--way-color-m1-immutable);

  --way-color-mission-assignment: #e8ecfa;

  --way-color-m4: #475166;

  --way-color-scrollbar: #c2c9d2;

  --way-color-mission-bg: #2f3845;

  --way-color-circle-purple: #935ba2;
  --way-color-circle-bg: #491057;
  --way-color-circle: #e5ceec;

  --way-header-signup: #f6f8ff;

  --way-color-brand-alt-l1: #7b438a;

  --way-color-warning-alt: #b28400;

  --way-color-warning-alt-l1: #C69F40;

  --way-color-warning-alt-bg: #f8f1e3;

  --way-color-pending: #2a65e5;

  --way-color-pending-bg: #e9f2ff;

  --way-color-refused : #640;

  --way-color-refused-bg : #FFE5CC;

  --way-color-title-secondary : var(--way-color-d1);
  --way-color-content-wrapper : var(--way-color-d2);
  --way-color-separator-mission : var(--way-color-m2);
  --way-color-content-wrapper-mis : #FFFFFF;
  --way-bgcolor-badge-counter : #d0d4e3;
  --way-color-badge-counter : var(--way-color-d2);
  --way-color-mission-date-badge : var(--way-color-brand);
  --way-bgcolor-mission-date-badge : var(--way-color-l1);
  --way-color-title-mission-date : var(--way-color-n2);

  --way-color-brand-alt-immutable: #cf79f7;

  --way-color-past-not-closed:#ff6b63;
  --way-color-transfert: #4dafff;
  --way-color-disposal: #ffc941;
  --way-color-airport-pickup: #3db932;
  --way-color-airport-dropoff: #ff80f2;

  --way-color-d2-alt: #475266;

  --way-color-second-block : var(--way-color-l2);
  --way-step-mission-trajet : #475266;
  --way-second-text-mission-detail: var(--way-color-d1-immutable);
  --way-color-third-block: var(--way-color-l2);
}

body.dark {
  --ion-background-color: #232933;
  --ion-background-color-rgb: 0, 0, 0;
  --secondary-background-color: var(--way-style-pagebg);

  --ion-text-color: var(--way-style-txt-primary);
  --ion-text-color-rgb: 255, 255, 255;

  --ion-border-color: transparent;
  --ion-toolbar-background: var(--way-style-blockbg);
  --ion-item-background: transparent;

  --ion-color-primary: var(--way-style-brand);
  --ion-color-primary-rgb: 42, 90, 164;
  --ion-color-primary-contrast: #232933;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #103f88;
  --ion-color-primary-tint: #2a5aa4;

  --ion-color-secondary: var(--way-style-brand);
  --ion-color-secondary-rgb: 80, 200, 255;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #46b0e0;
  --ion-color-secondary-tint: #62ceff;

  --ion-color-tertiary: #6a64ff;
  --ion-color-tertiary-rgb: 106, 100, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #5d58e0;
  --ion-color-tertiary-tint: #7974ff;

  --ion-color-success: #2fdf75;
  --ion-color-success-rgb: 47, 223, 117;
  --ion-color-success-contrast: #000000;
  --ion-color-success-contrast-rgb: 0, 0, 0;
  --ion-color-success-shade: #29c467;
  --ion-color-success-tint: #44e283;

  --ion-color-warning: #ff9900;
  --ion-color-warning-rgb: 255, 213, 52;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0bb2e;
  --ion-color-warning-tint: #ffd948;

  --ion-color-danger: #ff4961;
  --ion-color-danger-rgb: 255, 73, 97;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #e04055;
  --ion-color-danger-tint: #ff5b71;

  --ion-color-dark: #f4f5f8;
  --ion-color-dark-rgb: 244, 245, 248;
  --ion-color-dark-contrast: #000000;
  --ion-color-dark-contrast-rgb: 0, 0, 0;
  --ion-color-dark-shade: #d7d8da;
  --ion-color-dark-tint: #f5f6f9;

  --ion-color-medium: #989aa2;
  --ion-color-medium-rgb: 152, 154, 162;
  --ion-color-medium-contrast: #000000;
  --ion-color-medium-contrast-rgb: 0, 0, 0;
  --ion-color-medium-shade: #86888f;
  --ion-color-medium-tint: #a2a4ab;

  --ion-color-light: #222428;
  --ion-color-light-rgb: 34, 36, 40;
  --ion-color-light-contrast: #ffffff;
  --ion-color-light-contrast-rgb: 255, 255, 255;
  --ion-color-light-shade: #1e2023;
  --ion-color-light-tint: #383a3e;

  --ion-color-step-50: #1e1e1e;
  --ion-color-step-100: #2a2a2a;
  --ion-color-step-150: #363636;
  --ion-color-step-200: #414141;
  --ion-color-step-250: #4d4d4d;
  --ion-color-step-300: #595959;
  --ion-color-step-350: #656565;
  --ion-color-step-400: #717171;
  --ion-color-step-450: #7d7d7d;
  --ion-color-step-500: #898989;
  --ion-color-step-550: #949494;
  --ion-color-step-600: #a0a0a0;
  --ion-color-step-650: #acacac;
  --ion-color-step-700: #b8b8b8;
  --ion-color-step-750: #c4c4c4;
  --ion-color-step-800: #d0d0d0;
  --ion-color-step-850: #dbdbdb;
  --ion-color-step-900: #e7e7e7;
  --ion-color-step-950: #f3f3f3;

  /** waynium mid #1 **/
  --ion-color-waynium-light1: #d0d4e5;

  /** WAYNIUM PALETTE **/

  /** waynium brand */
  --way-color-brand: #cf79f7;

  --way-style-brand-button: var(--way-color-brand);

  --way-style-brand-button2: var(--ion-color-gray_waynium);
  --ion-color-gray_waynium2: var(--way-color-d2);
  --color-stepper-container-p: var(--ion-text-color);
  --way-optional-label: #a3acce;
  --way-input-label-color: var(--ion-background-color);
  --way-symbol-color: #ffffff;
  --ion-chip-select: #15191f;
  --ion-toggle-bg: #6c769a;
  --ion-accordion-color: #2f3845;

  /** waynium light #1 */
  --way-color-l1: #242731;

  /** waynium light #2 */
  --way-color-l2: #1c1d21;

  /** waynium mid #1 */
  --way-color-m1: #3b4051;

  /** waynium mid #2 */
  --way-color-m2: #555d76;

  /** waynium dark #1 */
  --way-color-d1: #ced2dd;

  /** waynium dark #2 */
  --way-color-d2: #ffffff;

  /********** COLOR STYLES **********/

  /* Page background **/
  --way-style-pagebg: var(--way-color-l2);

  /* Block background **/
  --way-style-blockbg: var(--way-color-l1);

  /** Brand **/
  --way-style-brand: var(--way-color-brand);

  --way-style-brand-contrast: var(--way-color-l1);

  --way-style-brand-block-d1: #491057;

  --way-style-brand-block-d2: #6d748c;

  --way-style-brand-block-d3: #ced2dd;

  --way-style-brand-block-d4: #1c1d21;

  --way-style-block: #1c1d21;

  --way-style-brand-button: #cf79f7;

  --way-style-brand-d1: var(--way-color-m2);

  --way-style-brand-d2: var(--way-color-m1);

  --way-color-brand-l1: #2f3845;

  /* Primary text **/
  --way-style-txt-primary: var(--way-color-d2);

  /* Secondary text **/
  --way-style-txt-secondary: var(--way-color-d1);

  /* Tertiary text **/
  --way-style-txt-tertiary: var(--way-color-d2);

  /* Borders & separators **/
  --way-style-block-border: var(--way-color-m1);
  --way-style-page-border: var(--way-color-m2);

  /* Box shadow **/
  --way-style-box-shadow: 0px 6px 7px -3px #0000002a;

  --way-style-radio-container-disabled-bg: #6c769a29;

  --way-style-radio-container-disabled-color: #6c769a;

  --button-color-1: #2f3845;
  --button-color-2: #ffffff;
  --button-color-3: var(--way-color-brand);

  --way-color-circle: #475266;
  --way-color-circle-bg: #fff;
  --way-color-circle-purple: #935ba2;

  --way-header-signup: #232933;

  --way-color-pending-bg: #164FCE;
  --way-color-pending: #E9F2FF;
  --way-color-title-secondary: #FFF;
  --way-color-content-wrapper: #FFFFFF;
  --way-color-separator-mission: #475266;
  --way-color-content-wrapper-mis: #313844;
  --way-bgcolor-badge-counter: #E8ECFA;
  --way-color-badge-couter: #313844;
  --way-color-mission-date-badge: #FFFFFF;
  --way-bgcolor-mission-date-badge: #475266;
  --way-color-title-mission-date: #FFF;
  --way-color-success-bg: #006F63;
  --way-color-success: #FFFFFF;
  --way-color-danger: #FFFFFF;
  --way-color-danger-bg: #AF111C;
  --way-color-brand-alt-l1 : #FFFFFF;
  --way-color-brand-l1: #7B438A;

  --way-color-warning-alt: #FFFFFF;
  --way-color-warning-alt-bg : #C69F40;
  --way-color-second-block : #313844;
  --way-step-mission-trajet : var(--way-color-d1);
  --way-second-text-mission-detail : var(--way-color-d1);
  --way-color-third-block: #2F3845;
}
.ion-color-waynium-danger {
  --ion-color-base: var(--ion-color-waynium-danger);
  --ion-color-base-rgb: var(--ion-color-waynium-danger-rgb);
  --ion-color-contrast: var(--ion-color-waynium-danger-contrast);
  --ion-color-contrast-rgb: var(--ion-color-waynium-danger-contrast-rgb);
  --ion-color-shade: var(--ion-color-waynium-danger-shade);
  --ion-color-tint: var(--ion-color-waynium-danger-tint);
}

.ion-color-waynium-warning {
  --ion-color-base: var(--ion-color-waynium-warning);
  --ion-color-base-rgb: var(--ion-color-waynium-warning-rgb);
  --ion-color-contrast: var(--ion-color-waynium-warning-contrast);
  --ion-color-contrast-rgb: var(--ion-color-waynium-warning-contrast-rgb);
  --ion-color-shade: var(--ion-color-waynium-warning-shade);
  --ion-color-tint: var(--ion-color-waynium-warning-tint);
}

.ion-color-waynium-ok {
  --ion-color-base: var(--ion-color-waynium-ok);
  --ion-color-base-rgb: var(--ion-color-waynium-ok-rgb);
  --ion-color-contrast: var(--ion-color-waynium-ok-contrast);
  --ion-color-contrast-rgb: var(--ion-color-waynium-ok-contrast-rgb);
  --ion-color-shade: var(--ion-color-waynium-ok-shade);
  --ion-color-tint: var(--ion-color-waynium-ok-tint);
}
