form {
  ion-item {
    ion-input, ion-textarea, ion-select, ion-datetime {
      --padding-start: 10px !important;
      --padding-end: 10px !important;
      --padding-top: 10px !important;
      --padding-bottom: 10px !important;
      border-radius: 5px;
      margin-bottom: 10px;
      margin-top: 10px;
    }

    ion-datetime {
      width: 100%;
    }

    ion-select {
      width: 100%;
      max-width: 100%;
    }

    ion-label {
      font-size: 1.5rem;
      font-weight: 500;

      &[position="stacked"] {
        margin-bottom: 10px !important;
      }

      &[position="fixed"] {
        min-width: 200px !important;
      }
    }
  }
}
