@import '../../../way-lib/src/theme/fonts/worksans.scss';

:root {
  --ion-font-family: 'Work Sans';
  font-family: 'Work Sans';
  font-size: 12px;

  .text-md {
    font-size: 1.16rem; // 14px
  }

  .text-lg {
    font-size: 1.33rem; // 16px
  }

  .text-xl {
    font-size: 1.5rem; // 18px
  }

  .text-2xl {
    font-size: 1.83rem; // 22px
  }

  .text-3xl {
    font-size: 2.33rem; // 28px
  }

  .font-bold {
    font-weight: bold;
  }

  .font-medium {
    font-weight: 500;
  }

  .font-semibold {
    font-weight: 600;
  }

  .uppercase {
    text-transform: uppercase;
  }

  .capitalize {
    text-transform: capitalize;
  }

  .text-center {
    text-align: center;
  }

  .title,
  .head-title,
  .subtitle {
    &.dark {
      color: var(--way-color-d2);
    }

    &.brand {
      color: var(--way-color-brand);
    }
  }

  .title,
  .head-title {
    color: #fff;
    font-weight: 700;
  }

  .head-title {
    font-size: 1.83rem;
    text-align: center;

    &.start {
      text-align: start;
    }
  }

  .title {
    font-size: 1.5rem;
    font-weight: 700;
  }

  .subtitle {
    font-size: 1.16rem;
    font-weight: 500;
    color: var(--ion-color-waynium-light1);

    &.lg {
      font-size: 1.33rem;
    }

    &.d1 {
      color: var(--way-color-d1);
    }
  }

  ion-accordion ion-label {
    font-size: 1.33rem;
    font-weight: 700;

    .optional-label {
      color: var(--way-optional-label);
      font-size: 1.16rem;
      font-weight: 500;
    }
  }

  ion-input {
    color: var(--ion-text-color);
    font-size: 1.16rem;
    font-weight: 500;
  }

  way-partner-signin ion-input {
    color: var(--way-color-d2-immutable);
    font-size: 1.16rem;
    font-weight: 500;
  }

  .form-error {
    color: var(--way-color-danger);
    margin: 0;
    font-weight: 500;
  }

  .title-secondary {
    color: var(--way-color-title-secondary);
    font-size: 1.16rem;
    font-weight: 600;
    text-transform: uppercase;
    margin: 0 0 1rem;

    &.no-margin {
      margin: 0;
    }
  }

  .no-margin {
    margin: 0;
  }

  .line-through {
    text-decoration: line-through;
  }
}

.card-content-ios p {
  font-size: 1rem;
}

.text-d1 {
  color: var(--way-color-d1);
}

.text-n2 {
  color: var(--way-color-n2);
}

.text-d2-alt {
  color: var(--way-color-d2-alt);
}

.text-danger {
  color: var(--way-color-danger);
}

.text-success {
  color: var(--way-color-success);
}

@import '../../../way-lib/src/theme/fonts/josefinsans.scss';
