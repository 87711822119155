ion-range {
  &.best-price {
    --knob-size: 20px;
    --knob-background: white;
    --knob-box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
    --bar-background-active: transparent;
    --bar-background: linear-gradient(270deg, #2ddf00 0%, #ffe600 50%, #ff0000 100%), #c4c4c4;
    --bar-height: 10px;
    --bar-border-radius: 4px;
  }
}

ion-input,
ion-select,
ion-datetime,
ion-textarea,
ion-checkbox {
  --checkmark-color: var(--ion-background-color);
  background: var(--ion-background-color);
  border-radius: 5px;
  box-shadow: 0px 2px 6px rgba(34, 26, 56, 0.05);
  --padding-start: 16px !important;
}

way-partner-signin ion-input {
  background: var(--ion-color-secondary-contrast) !important;
}

ion-datetime {
  color: var(--way-style-txt-primary);
}

ion-input[type='date'],
ion-input[type='time'] {
  &.ios:not(.has-value) {
    & > input {
      &::before {
        content: attr(placeholder);

        color: var(--placeholder-color);
        opacity: var(--placeholder-opacity);
      }
    }
  }
}
